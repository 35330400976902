import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
import CKDocs from '@workday/canvas-kit-docs/dist/mdx/react/button/button/Button.mdx';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const TabPanel = makeShortcode("TabPanel");
const PackageInfo = makeShortcode("PackageInfo");
const LegacyPatternLink = makeShortcode("LegacyPatternLink");
const InternalContent = makeShortcode("InternalContent");
const SideBySide = makeShortcode("SideBySide");
const Suggestion = makeShortcode("Suggestion");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <TabPanel data-id="Web" mdxType="TabPanel">
      <PackageInfo github="/modules/react/button" storybook="https://workday.github.io/canvas-kit/?path=/story/components-buttons--primary" figma="https://www.figma.com/design/6Y3uHyGKnS8m0DzWBLeL73/Canvas-Web-v11?node-id=19673-8659&t=gwsyYMobzrKIOcBB-4" package="@workday/canvas-kit-react" mdxType="PackageInfo" />
      <h2 {...{
        "id": "anatomy"
      }}>{`Anatomy`}</h2>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/588e0f5a0e8b493efe0dacb3bc986479/536c7/buttons-anatomy.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "22.972972972972975%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Image of a Primary and Secondary Button with annotation markers.",
              "title": "Image of a Primary and Secondary Button with annotation markers.",
              "src": "/static/588e0f5a0e8b493efe0dacb3bc986479/50383/buttons-anatomy.png",
              "srcSet": ["/static/588e0f5a0e8b493efe0dacb3bc986479/1efb2/buttons-anatomy.png 370w", "/static/588e0f5a0e8b493efe0dacb3bc986479/50383/buttons-anatomy.png 740w", "/static/588e0f5a0e8b493efe0dacb3bc986479/536c7/buttons-anatomy.png 1480w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <ol>
        <li parentName="ol"><strong parentName="li">{`Container (Conditional)`}</strong>{`: Houses the contents of the Button. Visual appearance differs based
on button type.`}</li>
        <li parentName="ol"><strong parentName="li">{`Label (Conditional)`}</strong>{`: Specific text describing the action. Refer to the
`}<a parentName="li" {...{
            "href": "/guidelines/content/ui-text/buttons-ctas"
          }}>{`Buttons and Calls to Action`}</a>{` section of the Content Style Guide.`}</li>
        <li parentName="ol"><strong parentName="li">{`Icon (Conditional)`}</strong>{`: Supplementary visual indicator that can be positioned alone or added to
the left or right of the label. Supplemental icons are used to promote the purpose of the Button.`}</li>
      </ol>
      <h2 {...{
        "id": "usage-guidance"
      }}>{`Usage Guidance`}</h2>
      <ul>
        <li parentName="ul">{`Buttons should indicate an action.`}</li>
        <li parentName="ul">{`They should be discoverable, easy to identify, and specific.`}</li>
        <li parentName="ul">{`Make Buttons look and feel clickable.`}</li>
        <li parentName="ul">{`Icons can be used alone or added to the left or right of the label. If used, the icon should
signify what the Button does.`}</li>
        <li parentName="ul">{`Use icon-only variants in dense environments or when space is limited.`}</li>
        <li parentName="ul">{`Use accessible `}<a parentName="li" {...{
            "href": "/components/popups/tooltip"
          }}>{`tooltips`}</a>{` with icon-only variants to help explain
ambiguous icons for everyone.`}</li>
        <li parentName="ul">{`When deciding which Button to use, consider the level of priority of the action, as well as how
much visual emphasis the Button should have in the context of the page it will live on. Be
intentional and refer to the examples below to determine which is right for your use case.`}</li>
      </ul>
      <h3 {...{
        "id": "when-to-use-something-else"
      }}>{`When to Use Something Else`}</h3>
      <ul>
        <li parentName="ul">{`Use Hyperlinks within a paragraph to navigate to another page. See the Button or Hyperlink section
of the `}<LegacyPatternLink href="/patterns/calls-to-action/" mdxType="LegacyPatternLink">{`Buttons and Calls to Action
pattern`}</LegacyPatternLink>{` for more information and best practices.`}</li>
        <li parentName="ul">{`Consider using `}<a parentName="li" {...{
            "href": "/components/inputs/checkbox"
          }}>{`checkbox`}</a>{`, `}<a parentName="li" {...{
            "href": "/components/inputs/switch"
          }}>{`switch`}</a>{`, or
`}<a parentName="li" {...{
            "href": "/components/buttons/segmented-control"
          }}>{`segmented control`}</a>{` when a component is needed that can
capture 2 togglable states.`}</li>
      </ul>
      <CKDocs mdxType="CKDocs" />
      <h2 {...{
        "id": "accessibility-guidelines"
      }}>{`Accessibility Guidelines`}</h2>
      <h3 {...{
        "id": "how-buttons-impact-the-accessible-experience"
      }}>{`How Buttons Impact the Accessible Experience`}</h3>
      <p>{`When buttons are disabled on the UI, color contrast guidelines do not apply to disabled components.
Minimum contrast guidelines set in WCAG 2.1 explicitly state
`}<a parentName="p" {...{
          "href": "https://www.w3.org/WAI/WCAG21/Understanding/contrast-minimum.html#inactive-controls"
        }}>{`disabled components are exempt`}</a>{`
from the guideline.`}</p>
      <p>{`When identical buttons are used repeatedly on a screen, users must correctly identify the context
around the buttons that cannot be distinguished from one another.`}</p>
      <ul>
        <li parentName="ul">{`For example: Pencil icon buttons used repeatedly on a profile screen, each designed to edit a
section of the profile. Providing uniquely descriptive names (e.g. “Edit photo”, “Edit contact
info”) for each icon button can be valuable for screen reader users.`}</li>
      </ul>
      <p>{`When icons are used inside of buttons containing text, a text alternative is only necessary when the
icon is communicating something about the button.`}</p>
      <ul>
        <li parentName="ul">{`For example: A ‘+’ icon used in a skill pill named “communication” signals an action that is not
expressed in the text.`}</li>
        <li parentName="ul">{`On the other hand: An icon of an eye used to decorate a “View Details” button is redundant and
should be hidden from screen readers.`}</li>
      </ul>
      <h3 {...{
        "id": "keyboard-interaction"
      }}>{`Keyboard Interaction`}</h3>
      <p>{`Each Button should have a focus indicator that is highly visible against the background and against
the non-focused state. Refer to `}<a parentName="p" {...{
          "href": "/guidelines/accessibility/accessible-color"
        }}>{`Accessible Colors`}</a>{` for more
information.`}</p>
      <p>{`Buttons should support the following keyboard interactions:`}</p>
      <ul>
        <li parentName="ul"><inlineCode parentName="li">{`Tab`}</inlineCode>{`: Focus on a button`}</li>
        <li parentName="ul"><inlineCode parentName="li">{`Enter`}</inlineCode>{` or `}<inlineCode parentName="li">{`Space`}</inlineCode>{`: Activate the button`}</li>
      </ul>
      <h3 {...{
        "id": "screen-reader-interaction"
      }}>{`Screen Reader Interaction`}</h3>
      <p>{`Buttons should communicate the following to users:`}</p>
      <ul>
        <li parentName="ul">{`The intended purpose, or function, of the Button`}</li>
        <li parentName="ul">{`If applicable, the toggle state of the Button when it has 2 states`}</li>
        <li parentName="ul">{`If applicable, an instruction that the Button will open a menu`}</li>
      </ul>
      <h3 {...{
        "id": "design-annotations-needed"
      }}>{`Design Annotations Needed`}</h3>
      <ul>
        <li parentName="ul">{`Write accessible name for icon-only button variants. Read more about
`}<a parentName="li" {...{
            "href": "/guidelines/accessibility/alt-text#what-is-non-text-content"
          }}>{`non-text content`}</a>{`.`}</li>
      </ul>
      <h3 {...{
        "id": "implementation-markup-needed"
      }}>{`Implementation Markup Needed`}</h3>
      <ul>
        <li parentName="ul">{`An `}<inlineCode parentName="li">{`aria-label`}</inlineCode>{` string is required for icon-only buttons and accessible
`}<a parentName="li" {...{
            "href": "/components/popups/tooltip"
          }}>{`tooltips`}</a>{` can show the icon's name for everyone.`}</li>
        <li parentName="ul">{`If the button has 2 toggle states, an `}<inlineCode parentName="li">{`aria-pressed`}</inlineCode>{` attribute is required.`}</li>
        <li parentName="ul">{`If the button has an attached menu, an `}<inlineCode parentName="li">{`aria-haspopup=”true”`}</inlineCode>{` attribute is required.`}</li>
        <li parentName="ul">{`[Included in component]`}{` Decorative `}<inlineCode parentName="li">{`<svg>`}</inlineCode>{` icons are hidden from assistive technology with
`}<inlineCode parentName="li">{`role=”presentation”`}</inlineCode>{` and `}<inlineCode parentName="li">{`focusable=”false”`}</inlineCode>{`.`}</li>
      </ul>
      <h2 {...{
        "id": "content-guidelines"
      }}>{`Content Guidelines`}</h2>
      <ul>
        <li parentName="ul">{`See the `}<a parentName="li" {...{
            "href": "/guidelines/content/ui-text/buttons-ctas"
          }}>{`Buttons and Calls to Action`}</a>{` page in the UI Text section
of the Content Style Guide for button language guidelines.`}</li>
        <li parentName="ul">{`When writing Tooltips to pair with icon-only variants, refer to the
`}<a parentName="li" {...{
            "href": "/guidelines/content/ui-text/tooltips"
          }}>{`Tooltips`}</a>{` section of the Content Style Guide.`}</li>
      </ul>
    </TabPanel>
    <InternalContent mdxType="InternalContent">
      <TabPanel data-id="iOS" mdxType="TabPanel">
        <PackageInfo figma="https://www.figma.com/file/MtaQChthxt2KIX8oveeWQw/Canvas-Mobile?node-id=20%3A4" mdxType="PackageInfo" />
        <h2 {...{
          "id": "anatomy-1"
        }}>{`Anatomy`}</h2>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/0be17d966139c06b2fc020abbad9a4ba/536c7/buttons-mobile-anatomy.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "22.972972972972975%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of a Mobile Primary and Secondary Button with annotation markers.",
                "title": "Image of a Mobile Primary and Secondary Button with annotation markers.",
                "src": "/static/0be17d966139c06b2fc020abbad9a4ba/50383/buttons-mobile-anatomy.png",
                "srcSet": ["/static/0be17d966139c06b2fc020abbad9a4ba/1efb2/buttons-mobile-anatomy.png 370w", "/static/0be17d966139c06b2fc020abbad9a4ba/50383/buttons-mobile-anatomy.png 740w", "/static/0be17d966139c06b2fc020abbad9a4ba/536c7/buttons-mobile-anatomy.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <ol>
          <li parentName="ol"><strong parentName="li">{`Container (Conditional)`}</strong>{`: Houses the contents of the Button. Visual appearance differs based
on button type.`}</li>
          <li parentName="ol"><strong parentName="li">{`Label`}</strong>{`: Specific text describing the action. Refer to the
`}<a parentName="li" {...{
              "href": "/guidelines/content/ui-text/buttons-ctas"
            }}>{`Buttons and Calls to Action`}</a>{` section of the Content Style Guide.`}</li>
          <li parentName="ol"><strong parentName="li">{`Icon (Optional)`}</strong>{`: Supplementary visual indicator positioned to the left or right of the label
used to promote the purpose of the Button.`}</li>
        </ol>
        <h2 {...{
          "id": "interaction-states"
        }}>{`Interaction States`}</h2>
        <p>{`Mobile Buttons have the Default/Inactive, Pressed, and Disabled interaction states.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/23b79def8fb19d26ddfc0ee64cc0ad6c/536c7/buttons-mobile-interaction-states.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "18.91891891891892%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of a Mobile Primary, Secondary, Tertiary Button with annotation markers.",
                "title": "Image of a Mobile Primary, Secondary, Tertiary Button with annotation markers.",
                "src": "/static/23b79def8fb19d26ddfc0ee64cc0ad6c/50383/buttons-mobile-interaction-states.png",
                "srcSet": ["/static/23b79def8fb19d26ddfc0ee64cc0ad6c/1efb2/buttons-mobile-interaction-states.png 370w", "/static/23b79def8fb19d26ddfc0ee64cc0ad6c/50383/buttons-mobile-interaction-states.png 740w", "/static/23b79def8fb19d26ddfc0ee64cc0ad6c/536c7/buttons-mobile-interaction-states.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h2 {...{
          "id": "usage-guidance-1"
        }}>{`Usage Guidance`}</h2>
        <ul>
          <li parentName="ul">{`Buttons should indicate an action.`}</li>
          <li parentName="ul">{`They should be discoverable, easy to identify, and specific.`}</li>
          <li parentName="ul">{`Make Buttons look and feel tappable.`}</li>
          <li parentName="ul">{`Adding an icon to the left or right of the label is optional. If used, the icon should signify
what the Button does.`}</li>
          <li parentName="ul">{`When deciding which Button to use, consider the level of priority of the action, as well as how
much visual emphasis the Button should have in the context of the page it will live on. Be
intentional and refer to the examples below to determine which button type is right for your use
case.`}</li>
        </ul>
        <h3 {...{
          "id": "when-to-use-something-else-1"
        }}>{`When to Use Something Else`}</h3>
        <ul>
          <li parentName="ul">{`Use Hyperlinks within a paragraph to navigate to another page. See the Button or Hyperlink section
of the `}<LegacyPatternLink href="/patterns/calls-to-action/" mdxType="LegacyPatternLink">{`Buttons and Calls to Action
pattern`}</LegacyPatternLink>{` for more information and best practices.`}</li>
        </ul>
        <h3 {...{
          "id": "mobile-guidance"
        }}>{`Mobile Guidance`}</h3>
        <ul>
          <li parentName="ul">{`Large Buttons on Mobile have larger typography to increase readability on smaller screens`}</li>
          <li parentName="ul">{`Mobile Buttons have reduced horizontal padding to decrease the likelihood of truncated text.`}</li>
          <li parentName="ul">{`XS Buttons are not supported on Mobile.`}</li>
        </ul>
        <h3 {...{
          "id": "tap-targets"
        }}>{`Tap Targets`}</h3>
        <p>{`Mobile Buttons have a minimum tap target height of 48dp to ensure that they are easy to interact
with. The entire area of the Button should be tappable. On Medium and Small Size Buttons, an
additional tappable top and bottom padding are added to meet this 48dp tappable height requirement.
Make sure spacing between Buttons and other UI elements is enough to avoid intersecting tap targets.`}</p>
        <SideBySide mdxType="SideBySide">
          <Suggestion status="do" guidance="Use a minimum tap target of 48dp to ensure the Button is easy to interact with." mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "681px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/8fd6446127d798ff9e2e39db0e8bea1e/8ce52/button-mobile-taptarget-do.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "64.5945945945946%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Image demonstrating a Medium Primary Button with a minimum tap target of 48dp.",
                    "title": "Image demonstrating a Medium Primary Button with a minimum tap target of 48dp.",
                    "src": "/static/8fd6446127d798ff9e2e39db0e8bea1e/8ce52/button-mobile-taptarget-do.png",
                    "srcSet": ["/static/8fd6446127d798ff9e2e39db0e8bea1e/1efb2/button-mobile-taptarget-do.png 370w", "/static/8fd6446127d798ff9e2e39db0e8bea1e/8ce52/button-mobile-taptarget-do.png 681w"],
                    "sizes": "(max-width: 681px) 100vw, 681px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
          <Suggestion status="error" guidance="Use a tap target height smaller than 48dp, even if the Button might appear smaller visually." mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "681px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/8c4a5857bc8d802f1d2b096c69c4ffcb/8ce52/button-mobile-taptarget-dont.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "64.5945945945946%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Image demonstrating a Medium Primary Button without a minimum tap target of 48dp.",
                    "title": "Image demonstrating a Medium Primary Button without a minimum tap target of 48dp.",
                    "src": "/static/8c4a5857bc8d802f1d2b096c69c4ffcb/8ce52/button-mobile-taptarget-dont.png",
                    "srcSet": ["/static/8c4a5857bc8d802f1d2b096c69c4ffcb/1efb2/button-mobile-taptarget-dont.png 370w", "/static/8c4a5857bc8d802f1d2b096c69c4ffcb/8ce52/button-mobile-taptarget-dont.png 681w"],
                    "sizes": "(max-width: 681px) 100vw, 681px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
        </SideBySide>
        <h3 {...{
          "id": "icon-placement"
        }}>{`Icon Placement`}</h3>
        <p>{`Icons can be used within Buttons to help reinforce the meaning of their labels, or to hint as to
what tapping on the Button will accomplish.`}</p>
        <ul>
          <li parentName="ul"><strong parentName="li">{`No Icon`}</strong>{`: Used when no icons are needed to reinforce the meaning of the Button or describe
interactivity.`}</li>
          <li parentName="ul"><strong parentName="li">{`Leading Icon`}</strong>{`: Icons placed in front of a label are used to reinforce the meaning of the Button
label and are purely descriptive. For example, adding a plus icon will reinforce the actions of an
"Add" button.`}</li>
          <li parentName="ul"><strong parentName="li">{`Trailing Icon`}</strong>{`: Icons placed to the right of a label are used to hint the interaction to follow
after tapping on the Button. For example, a Button with a "Caret Down" icon might open a menu with
more options.`}</li>
          <li parentName="ul"><strong parentName="li">{`Icon Only`}</strong>{`: Used for dense environments, or when space is limited on the screen.`}</li>
        </ul>
        <h3 {...{
          "id": "truncation"
        }}>{`Truncation`}</h3>
        <p>{`Button labels should be kept short and concise. Each Button should represent a single action the
user can take, never multiple. However, do be mindful if a single action triggers multiple system
processes (e.g. “Squash and Merge” closes a branch and moves it to another one in many coding
environments).`}</p>
        <SideBySide mdxType="SideBySide">
          <Suggestion guidance="Try to keep label text concise. If needed, labels can be truncated to avoid wrapping." mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "681px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/2ce7c244958695b289185b42b4af9b6d/8ce52/button-mobile-truncation-do.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "64.5945945945946%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Image demonstrating a Primary Button with a label that wraps.",
                    "title": "Image demonstrating a Primary Button with a label that wraps.",
                    "src": "/static/2ce7c244958695b289185b42b4af9b6d/8ce52/button-mobile-truncation-do.png",
                    "srcSet": ["/static/2ce7c244958695b289185b42b4af9b6d/1efb2/button-mobile-truncation-do.png 370w", "/static/2ce7c244958695b289185b42b4af9b6d/8ce52/button-mobile-truncation-do.png 681w"],
                    "sizes": "(max-width: 681px) 100vw, 681px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
          <Suggestion status="error" guidance="Wrap Button text to the next line, with the exception of dynamic type scaling." mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "681px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/acd45935820ddb3bded3b3b990ff1238/8ce52/button-mobile-truncation-dont.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "64.5945945945946%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Image demonstrating a Medium Primary Button without a minimum tap target of 48dp.",
                    "title": "Image demonstrating a Medium Primary Button without a minimum tap target of 48dp.",
                    "src": "/static/acd45935820ddb3bded3b3b990ff1238/8ce52/button-mobile-truncation-dont.png",
                    "srcSet": ["/static/acd45935820ddb3bded3b3b990ff1238/1efb2/button-mobile-truncation-dont.png 370w", "/static/acd45935820ddb3bded3b3b990ff1238/8ce52/button-mobile-truncation-dont.png 681w"],
                    "sizes": "(max-width: 681px) 100vw, 681px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
        </SideBySide>
        <h2 {...{
          "id": "variations"
        }}>{`Variations`}</h2>
        <h3 {...{
          "id": "primary-button"
        }}>{`Primary Button`}</h3>
        <p>{`Primary Buttons are high emphasis. Use minimally to draw attention to the highest priority action.
Too many primary buttons make it confusing for the user to understand what action they should take.
Not all screens require a Primary Button.`}</p>
        <p>{`Primary Buttons have three sizes: small, medium, and large. Icons are supported for every size and
can be positioned to the left or right.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/5f52223190310853311f832a659a84c9/536c7/button-mobile-primary-icons.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "18.91891891891892%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of Primary No Icon, Leading, Trailing, and Icon Only variants",
                "title": "Image of Primary No Icon, Leading, Trailing, and Icon Only variants",
                "src": "/static/5f52223190310853311f832a659a84c9/50383/button-mobile-primary-icons.png",
                "srcSet": ["/static/5f52223190310853311f832a659a84c9/1efb2/button-mobile-primary-icons.png 370w", "/static/5f52223190310853311f832a659a84c9/50383/button-mobile-primary-icons.png 740w", "/static/5f52223190310853311f832a659a84c9/536c7/button-mobile-primary-icons.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`Primary Buttons also have an inverse variant. While it looks similar to the default Secondary
Button, the default outline as well as the hover and focus states are different. Use this variant
when you need to place a Primary Button on a dark or colorful background such as blueberry400.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/cbd5b0d41b76ca80f7dbac8787a0e9c9/536c7/button-mobile-primary-inverse.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "18.91891891891892%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of Inverse Primary Buttons",
                "title": "Image of Inverse Primary Buttons",
                "src": "/static/cbd5b0d41b76ca80f7dbac8787a0e9c9/50383/button-mobile-primary-inverse.png",
                "srcSet": ["/static/cbd5b0d41b76ca80f7dbac8787a0e9c9/1efb2/button-mobile-primary-inverse.png 370w", "/static/cbd5b0d41b76ca80f7dbac8787a0e9c9/50383/button-mobile-primary-inverse.png 740w", "/static/cbd5b0d41b76ca80f7dbac8787a0e9c9/536c7/button-mobile-primary-inverse.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`Primary Buttons and their inverse variants support the Inactive, Pressed, and Disabled Interaction
States.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/ec20bcf1d6341e47e59e0c34e0097623/536c7/button-mobile-primary-interaction.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "18.91891891891892%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of Primary Buttons interaction states",
                "title": "Image of Primary Buttons interaction states",
                "src": "/static/ec20bcf1d6341e47e59e0c34e0097623/50383/button-mobile-primary-interaction.png",
                "srcSet": ["/static/ec20bcf1d6341e47e59e0c34e0097623/1efb2/button-mobile-primary-interaction.png 370w", "/static/ec20bcf1d6341e47e59e0c34e0097623/50383/button-mobile-primary-interaction.png 740w", "/static/ec20bcf1d6341e47e59e0c34e0097623/536c7/button-mobile-primary-interaction.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h3 {...{
          "id": "secondary-button"
        }}>{`Secondary Button`}</h3>
        <p>{`Secondary Buttons have a medium level of emphasis. Use them for non-critical actions. Secondary
Buttons can be used on most pages without restrictions and work well for multiple actions of equal
weight. They can be used in conjunction with a Primary Button or independently.`}</p>
        <p>{`Secondary Buttons have three sizes: small, medium, and large. Icons are supported for every size and
can be positioned to the left or right.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/b94b9d3a8e991f76d4bead2c8d3bad94/536c7/button-mobile-secondary-icons.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "18.91891891891892%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of Secondary No Icon, Leading, Trailing, and Icon Only variants",
                "title": "Image of Secondary No Icon, Leading, Trailing, and Icon Only variants",
                "src": "/static/b94b9d3a8e991f76d4bead2c8d3bad94/50383/button-mobile-secondary-icons.png",
                "srcSet": ["/static/b94b9d3a8e991f76d4bead2c8d3bad94/1efb2/button-mobile-secondary-icons.png 370w", "/static/b94b9d3a8e991f76d4bead2c8d3bad94/50383/button-mobile-secondary-icons.png 740w", "/static/b94b9d3a8e991f76d4bead2c8d3bad94/536c7/button-mobile-secondary-icons.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`Secondary Buttons also have an inverse variant. Use this when you need to place a Secondary Button
on a dark or colorful background such as blueberry400.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/843d6b75fb6c02dac8eb6a8cf14fd01d/536c7/button-mobile-secondary-inverse.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "18.91891891891892%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of Inverse Secondary Buttons",
                "title": "Image of Inverse Secondary Buttons",
                "src": "/static/843d6b75fb6c02dac8eb6a8cf14fd01d/50383/button-mobile-secondary-inverse.png",
                "srcSet": ["/static/843d6b75fb6c02dac8eb6a8cf14fd01d/1efb2/button-mobile-secondary-inverse.png 370w", "/static/843d6b75fb6c02dac8eb6a8cf14fd01d/50383/button-mobile-secondary-inverse.png 740w", "/static/843d6b75fb6c02dac8eb6a8cf14fd01d/536c7/button-mobile-secondary-inverse.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`Secondary Buttons and their inverse variants support the Inactive, Pressed, and Disabled Interaction
States.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/b484f850b1d0d821876c287fa299bd26/536c7/button-mobile-secondary-interaction.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "18.91891891891892%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of Secondary Buttons interaction states",
                "title": "Image of Secondary Buttons interaction states",
                "src": "/static/b484f850b1d0d821876c287fa299bd26/50383/button-mobile-secondary-interaction.png",
                "srcSet": ["/static/b484f850b1d0d821876c287fa299bd26/1efb2/button-mobile-secondary-interaction.png 370w", "/static/b484f850b1d0d821876c287fa299bd26/50383/button-mobile-secondary-interaction.png 740w", "/static/b484f850b1d0d821876c287fa299bd26/536c7/button-mobile-secondary-interaction.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h3 {...{
          "id": "tertiary-button"
        }}>{`Tertiary Button`}</h3>
        <p>{`Tertiary Buttons have the lowest emphasis. Use for less important actions that the user may not
often be looking to do. Tertiary Buttons have lower prominence as its container is not visible until
it is interacted with. Use Tertiary Buttons for supplemental actions such as “View More”, “Read
More” or “Select a File”. Tertiary Buttons are frequently used on List Items.`}</p>
        <p>{`Tertiary Buttons have two sizes on mobile: small, and medium. Icons are supported for every size and
can be positioned to the left or right with the iconPosition prop.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/cbb9de5ebbaeb5634e2c390ac152c087/536c7/button-mobile-tertiary-icons.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "18.91891891891892%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of Tertiary No Icon, Leading, Trailing, and Icon Only variants",
                "title": "Image of Tertiary No Icon, Leading, Trailing, and Icon Only variants",
                "src": "/static/cbb9de5ebbaeb5634e2c390ac152c087/50383/button-mobile-tertiary-icons.png",
                "srcSet": ["/static/cbb9de5ebbaeb5634e2c390ac152c087/1efb2/button-mobile-tertiary-icons.png 370w", "/static/cbb9de5ebbaeb5634e2c390ac152c087/50383/button-mobile-tertiary-icons.png 740w", "/static/cbb9de5ebbaeb5634e2c390ac152c087/536c7/button-mobile-tertiary-icons.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`Tertiary Buttons also have an inverse variant. Use this when you need to place a Tertiary Button on
a dark or colorful background such as blueberry400.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/81f110ac2ec4b2932a2c8d04cd18158d/536c7/button-mobile-tertiary-inverse.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "18.91891891891892%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of Inverse Tertiary Buttons",
                "title": "Image of Inverse Tertiary Buttons",
                "src": "/static/81f110ac2ec4b2932a2c8d04cd18158d/50383/button-mobile-tertiary-inverse.png",
                "srcSet": ["/static/81f110ac2ec4b2932a2c8d04cd18158d/1efb2/button-mobile-tertiary-inverse.png 370w", "/static/81f110ac2ec4b2932a2c8d04cd18158d/50383/button-mobile-tertiary-inverse.png 740w", "/static/81f110ac2ec4b2932a2c8d04cd18158d/536c7/button-mobile-tertiary-inverse.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`Tertiary Buttons and their inverse variants support the Inactive, Pressed, and Disabled Interaction
States.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/a9308386279e69858480c5f88981e7df/536c7/button-mobile-tertiary-interaction.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "18.91891891891892%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of Tertiary Buttons interaction states",
                "title": "Image of Tertiary Buttons interaction states",
                "src": "/static/a9308386279e69858480c5f88981e7df/50383/button-mobile-tertiary-interaction.png",
                "srcSet": ["/static/a9308386279e69858480c5f88981e7df/1efb2/button-mobile-tertiary-interaction.png 370w", "/static/a9308386279e69858480c5f88981e7df/50383/button-mobile-tertiary-interaction.png 740w", "/static/a9308386279e69858480c5f88981e7df/536c7/button-mobile-tertiary-interaction.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h2 {...{
          "id": "api-guidelines"
        }}>{`API Guidelines`}</h2>
        <h3 {...{
          "id": "primary-button-1"
        }}>{`Primary Button`}</h3>
        <h4 {...{
          "id": "primarybuttonstyle"
        }}><inlineCode parentName="h4">{`PrimaryButtonStyle`}</inlineCode></h4>
        <pre><code parentName="pre" {...{
            "className": "language-swift"
          }}>{`public struct PrimaryButtonStyle: BasePrimitiveButtonStyle
`}</code></pre>
        <p>{`CanvasKit design system primary button style.`}</p>
        <h4 {...{
          "id": "methods"
        }}>{`Methods`}</h4>
        <h4 {...{
          "id": "initcontextsizeisicononlyiconnameiconalignmenticonaccessibilitylabel"
        }}><inlineCode parentName="h4">{`init(context:size:isIconOnly:iconName:iconAlignment:iconAccessibilityLabel:)`}</inlineCode></h4>
        <pre><code parentName="pre" {...{
            "className": "language-swift"
          }}>{`public init(
    context: Binding<SemanticContext>,
    size: ButtonSize,
    isIconOnly: Bool,
    iconName: String?,
    iconAlignment: HorizontalAlignment,
    iconAccessibilityLabel: String
)
`}</code></pre>
        <h4 {...{
          "id": "parameters"
        }}>{`Parameters`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Name`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`context`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`the `}<inlineCode parentName="td">{`SemanticContext`}</inlineCode>{` in which the button appears.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`size`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`the size class of the button.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`isIconOnly`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`whether the button is just an icon.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`iconName`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Optionl name of the icon, i.e if empty string or nil, label-only button.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`iconAlignment`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`the alignment of the icon within the button oval.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`iconAccessibilityLabel`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`the accessibility label for the icon. (Use an empty string unless `}<inlineCode parentName="td">{`isIconOnly`}</inlineCode>{` is true.)`}</td>
            </tr>
          </tbody>
        </table>
        <h4 {...{
          "id": "makebodyconfiguration"
        }}><inlineCode parentName="h4">{`makeBody(configuration:)`}</inlineCode></h4>
        <pre><code parentName="pre" {...{
            "className": "language-swift"
          }}>{`public func makeBody(configuration: Configuration) -> some View
`}</code></pre>
        <h4 {...{
          "id": "parameters-1"
        }}>{`Parameters`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Name`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`configuration`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The properties of the button.`}</td>
            </tr>
          </tbody>
        </table>
        <h3 {...{
          "id": "secondary-button-1"
        }}>{`Secondary Button`}</h3>
        <h3 {...{
          "id": "secondarybuttonstyle"
        }}><inlineCode parentName="h3">{`SecondaryButtonStyle`}</inlineCode></h3>
        <pre><code parentName="pre" {...{
            "className": "language-swift"
          }}>{`public struct SecondaryButtonStyle: BasePrimitiveButtonStyle
`}</code></pre>
        <p>{`CanvasKit design system secondary button style.`}</p>
        <h4 {...{
          "id": "methods-1"
        }}>{`Methods`}</h4>
        <h4 {...{
          "id": "initcontextsizeisicononlyiconnameiconalignmenticonaccessibilitylabel-1"
        }}><inlineCode parentName="h4">{`init(context:size:isIconOnly:iconName:iconAlignment:iconAccessibilityLabel:)`}</inlineCode></h4>
        <pre><code parentName="pre" {...{
            "className": "language-swift"
          }}>{`public init(
    context: Binding<SemanticContext>,
    size: ButtonSize,
    isIconOnly: Bool,
    iconName: String?,
    iconAlignment: HorizontalAlignment,
    iconAccessibilityLabel: String
)
`}</code></pre>
        <h4 {...{
          "id": "parameters-2"
        }}>{`Parameters`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Name`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`context`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`the `}<inlineCode parentName="td">{`SemanticContext`}</inlineCode>{` in which the button appears.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`size`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`the size class of the button.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`isIconOnly`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`whether the button is just an icon.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`iconName`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Optionl name of the icon, i.e if empty string or nil, label-only button.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`iconAlignment`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`the alignment of the icon within the button oval.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`iconAccessibilityLabel`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`the accessibility label for the icon. (Use an empty string unless `}<inlineCode parentName="td">{`isIconOnly`}</inlineCode>{` is true.)`}</td>
            </tr>
          </tbody>
        </table>
        <h4 {...{
          "id": "makebodyconfiguration-1"
        }}><inlineCode parentName="h4">{`makeBody(configuration:)`}</inlineCode></h4>
        <pre><code parentName="pre" {...{
            "className": "language-swift"
          }}>{`public func makeBody(configuration: Configuration) -> some View
`}</code></pre>
        <h4 {...{
          "id": "parameters-3"
        }}>{`Parameters`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Name`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`configuration`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The properties of the button.`}</td>
            </tr>
          </tbody>
        </table>
        <h3 {...{
          "id": "tertiary-button-1"
        }}>{`Tertiary Button`}</h3>
        <h3 {...{
          "id": "tertiarybuttonstyle"
        }}><inlineCode parentName="h3">{`TertiaryButtonStyle`}</inlineCode></h3>
        <pre><code parentName="pre" {...{
            "className": "language-swift"
          }}>{`public struct TertiaryButtonStyle: BasePrimitiveButtonStyle
`}</code></pre>
        <p>{`CanvasKit design system tertiary button style.`}</p>
        <h4 {...{
          "id": "methods-2"
        }}>{`Methods`}</h4>
        <h4 {...{
          "id": "initcontextsizeisicononlyiconnameiconalignmenticonaccessibilitylabel-2"
        }}><inlineCode parentName="h4">{`init(context:size:isIconOnly:iconName:iconAlignment:iconAccessibilityLabel:)`}</inlineCode></h4>
        <pre><code parentName="pre" {...{
            "className": "language-swift"
          }}>{`public init(
    context: Binding<SemanticContext>,
    size: ButtonSize,
    isIconOnly: Bool,
    iconName: String?,
    iconAlignment: HorizontalAlignment,
    iconAccessibilityLabel: String
)
`}</code></pre>
        <h4 {...{
          "id": "parameters-4"
        }}>{`Parameters`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Name`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`context`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`the `}<inlineCode parentName="td">{`SemanticContext`}</inlineCode>{` in which the button appears.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`size`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`the size class of the button.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`isIconOnly`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`whether the button is just an icon.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`iconName`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Optionl name of the icon, i.e if empty string or nil, label-only button.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`iconAlignment`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`the alignment of the icon within the button oval.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`iconAccessibilityLabel`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`the accessibility label for the icon. (Use an empty string unless `}<inlineCode parentName="td">{`isIconOnly`}</inlineCode>{` is true.)`}</td>
            </tr>
          </tbody>
        </table>
        <h4 {...{
          "id": "makebodyconfiguration-2"
        }}><inlineCode parentName="h4">{`makeBody(configuration:)`}</inlineCode></h4>
        <pre><code parentName="pre" {...{
            "className": "language-swift"
          }}>{`public func makeBody(configuration: Configuration) -> some View
`}</code></pre>
        <h4 {...{
          "id": "parameters-5"
        }}>{`Parameters`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Name`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`configuration`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The properties of the button.`}</td>
            </tr>
          </tbody>
        </table>
        <h2 {...{
          "id": "accessibility-guidelines-1"
        }}>{`Accessibility Guidelines`}</h2>
        <p>{`Refreshed mobile guidelines will be coming soon!`}</p>
        <h2 {...{
          "id": "content-guidelines-1"
        }}>{`Content Guidelines`}</h2>
        <ul>
          <li parentName="ul">{`See the `}<a parentName="li" {...{
              "href": "/guidelines/content/ui-text/buttons-ctas"
            }}>{`Buttons and Calls to Action`}</a>{` page in the UI Text section
of the Content Style Guide for button language guidelines.`}</li>
          <li parentName="ul">{`When writing Tooltips to pair with Icon Buttons, refer to the
`}<a parentName="li" {...{
              "href": "/guidelines/content/ui-text/tooltips"
            }}>{`Tooltips`}</a>{` section of the Content Style Guide.`}</li>
        </ul>
      </TabPanel>
    </InternalContent>
    <InternalContent mdxType="InternalContent">
      <TabPanel data-id="Android" mdxType="TabPanel">
        <PackageInfo figma="https://www.figma.com/file/MtaQChthxt2KIX8oveeWQw/Canvas-Mobile?node-id=20%3A4" mdxType="PackageInfo" />
        <h2 {...{
          "id": "anatomy-2"
        }}>{`Anatomy`}</h2>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/0be17d966139c06b2fc020abbad9a4ba/536c7/buttons-mobile-anatomy.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "22.972972972972975%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of a Mobile Primary and Secondary Button with annotation markers.",
                "title": "Image of a Mobile Primary and Secondary Button with annotation markers.",
                "src": "/static/0be17d966139c06b2fc020abbad9a4ba/50383/buttons-mobile-anatomy.png",
                "srcSet": ["/static/0be17d966139c06b2fc020abbad9a4ba/1efb2/buttons-mobile-anatomy.png 370w", "/static/0be17d966139c06b2fc020abbad9a4ba/50383/buttons-mobile-anatomy.png 740w", "/static/0be17d966139c06b2fc020abbad9a4ba/536c7/buttons-mobile-anatomy.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <ol>
          <li parentName="ol"><strong parentName="li">{`Container (Conditional)`}</strong>{`: Houses the contents of the Button. Visual appearance differs based
on button type.`}</li>
          <li parentName="ol"><strong parentName="li">{`Label`}</strong>{`: Specific text describing the action. Refer to the
`}<a parentName="li" {...{
              "href": "/guidelines/content/ui-text/buttons-ctas"
            }}>{`Buttons and Calls to Action`}</a>{` section of the Content Style Guide.`}</li>
          <li parentName="ol"><strong parentName="li">{`Icon (Optional)`}</strong>{`: Supplementary visual indicator positioned to the left or right of the label
used to promote the purpose of the Button.`}</li>
        </ol>
        <h2 {...{
          "id": "interaction-states-1"
        }}>{`Interaction States`}</h2>
        <p>{`Mobile Buttons have the Default/Inactive, Pressed, and Disabled interaction states.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/23b79def8fb19d26ddfc0ee64cc0ad6c/536c7/buttons-mobile-interaction-states.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "18.91891891891892%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of a Mobile Primary, Secondary, Tertiary Button with annotation markers.",
                "title": "Image of a Mobile Primary, Secondary, Tertiary Button with annotation markers.",
                "src": "/static/23b79def8fb19d26ddfc0ee64cc0ad6c/50383/buttons-mobile-interaction-states.png",
                "srcSet": ["/static/23b79def8fb19d26ddfc0ee64cc0ad6c/1efb2/buttons-mobile-interaction-states.png 370w", "/static/23b79def8fb19d26ddfc0ee64cc0ad6c/50383/buttons-mobile-interaction-states.png 740w", "/static/23b79def8fb19d26ddfc0ee64cc0ad6c/536c7/buttons-mobile-interaction-states.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h2 {...{
          "id": "usage-guidance-2"
        }}>{`Usage Guidance`}</h2>
        <ul>
          <li parentName="ul">{`Buttons should indicate an action.`}</li>
          <li parentName="ul">{`They should be discoverable, easy to identify, and specific.`}</li>
          <li parentName="ul">{`Make Buttons look and feel tappable.`}</li>
          <li parentName="ul">{`Adding an icon to the left or right of the label is optional. If used, the icon should signify
what the Button does.`}</li>
          <li parentName="ul">{`When deciding which Button to use, consider the level of priority of the action, as well as how
much visual emphasis the Button should have in the context of the page it will live on. Be
intentional and refer to the examples below to determine which button type is right for your use
case.`}</li>
        </ul>
        <h3 {...{
          "id": "when-to-use-something-else-2"
        }}>{`When to Use Something Else`}</h3>
        <ul>
          <li parentName="ul">{`Use Hyperlinks within a paragraph to navigate to another page. See the Button or Hyperlink section
of the `}<LegacyPatternLink href="/patterns/calls-to-action/" mdxType="LegacyPatternLink">{`Buttons and Calls to Action
pattern`}</LegacyPatternLink>{` for more information and best practices.`}</li>
        </ul>
        <h3 {...{
          "id": "mobile-guidance-1"
        }}>{`Mobile Guidance`}</h3>
        <ul>
          <li parentName="ul">{`Large Buttons on Mobile have larger typography to increase readability on smaller screens`}</li>
          <li parentName="ul">{`Mobile Buttons have reduced horizontal padding to decrease the likelihood of truncated text.`}</li>
          <li parentName="ul">{`XS Buttons are not supported on Mobile.`}</li>
        </ul>
        <h3 {...{
          "id": "tap-targets-1"
        }}>{`Tap Targets`}</h3>
        <p>{`Mobile Buttons have a minimum tap target height of 48dp to ensure that they are easy to interact
with. The entire area of the Button should be tappable. On Medium and Small Size Buttons, an
additional tappable top and bottom padding are added to meet this 48dp tappable height requirement.
Make sure spacing between Buttons and other UI elements is enough to avoid intersecting tap targets.`}</p>
        <SideBySide mdxType="SideBySide">
          <Suggestion status="do" guidance="Use a minimum tap target of 48dp to ensure the Button is easy to interact with." mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "681px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/8fd6446127d798ff9e2e39db0e8bea1e/8ce52/button-mobile-taptarget-do.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "64.5945945945946%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Image demonstrating a Medium Primary Button with a minimum tap target of 48dp.",
                    "title": "Image demonstrating a Medium Primary Button with a minimum tap target of 48dp.",
                    "src": "/static/8fd6446127d798ff9e2e39db0e8bea1e/8ce52/button-mobile-taptarget-do.png",
                    "srcSet": ["/static/8fd6446127d798ff9e2e39db0e8bea1e/1efb2/button-mobile-taptarget-do.png 370w", "/static/8fd6446127d798ff9e2e39db0e8bea1e/8ce52/button-mobile-taptarget-do.png 681w"],
                    "sizes": "(max-width: 681px) 100vw, 681px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
          <Suggestion status="error" guidance="Use a tap target height smaller than 48dp, even if the Button might appear smaller visually." mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "681px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/8c4a5857bc8d802f1d2b096c69c4ffcb/8ce52/button-mobile-taptarget-dont.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "64.5945945945946%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Image demonstrating a Medium Primary Button without a minimum tap target of 48dp.",
                    "title": "Image demonstrating a Medium Primary Button without a minimum tap target of 48dp.",
                    "src": "/static/8c4a5857bc8d802f1d2b096c69c4ffcb/8ce52/button-mobile-taptarget-dont.png",
                    "srcSet": ["/static/8c4a5857bc8d802f1d2b096c69c4ffcb/1efb2/button-mobile-taptarget-dont.png 370w", "/static/8c4a5857bc8d802f1d2b096c69c4ffcb/8ce52/button-mobile-taptarget-dont.png 681w"],
                    "sizes": "(max-width: 681px) 100vw, 681px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
        </SideBySide>
        <h3 {...{
          "id": "icon-placement-1"
        }}>{`Icon Placement`}</h3>
        <p>{`Icons can be used within Buttons to help reinforce the meaning of their labels, or to hint as to
what tapping on the Button will accomplish.`}</p>
        <ul>
          <li parentName="ul"><strong parentName="li">{`No Icon`}</strong>{`: Used when no icons are needed to reinforce the meaning of the Button or describe
interactivity.`}</li>
          <li parentName="ul"><strong parentName="li">{`Leading Icon`}</strong>{`: Icons placed in front of a label are used to reinforce the meaning of the Button
label and are purely descriptive. For example, adding a plus icon will reinforce the actions of an
"Add" button.`}</li>
          <li parentName="ul"><strong parentName="li">{`Trailing Icon`}</strong>{`: Icons placed to the right of a label are used to hint the interaction to follow
after tapping on the Button. For example, a Button with a "Caret Down" icon might open a menu with
more options.`}</li>
          <li parentName="ul"><strong parentName="li">{`Icon Only`}</strong>{`: Used for dense environments, or when space is limited on the screen.`}</li>
        </ul>
        <h3 {...{
          "id": "truncation-1"
        }}>{`Truncation`}</h3>
        <p>{`Button labels should be kept short and concise. Each Button should represent a single action the
user can take, never multiple. However, do be mindful if a single action triggers multiple system
processes (e.g. “Squash and Merge” closes a branch and moves it to another one in many coding
environments).`}</p>
        <SideBySide mdxType="SideBySide">
          <Suggestion guidance="Try to keep label text concise. If needed, labels can be truncated to avoid wrapping." mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "681px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/2ce7c244958695b289185b42b4af9b6d/8ce52/button-mobile-truncation-do.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "64.5945945945946%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Image demonstrating a Primary Button with a label that wraps.",
                    "title": "Image demonstrating a Primary Button with a label that wraps.",
                    "src": "/static/2ce7c244958695b289185b42b4af9b6d/8ce52/button-mobile-truncation-do.png",
                    "srcSet": ["/static/2ce7c244958695b289185b42b4af9b6d/1efb2/button-mobile-truncation-do.png 370w", "/static/2ce7c244958695b289185b42b4af9b6d/8ce52/button-mobile-truncation-do.png 681w"],
                    "sizes": "(max-width: 681px) 100vw, 681px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
          <Suggestion status="error" guidance="Wrap Button text to the next line, with the exception of dynamic type scaling." mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "681px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/acd45935820ddb3bded3b3b990ff1238/8ce52/button-mobile-truncation-dont.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "64.5945945945946%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Image demonstrating a Medium Primary Button without a minimum tap target of 48dp.",
                    "title": "Image demonstrating a Medium Primary Button without a minimum tap target of 48dp.",
                    "src": "/static/acd45935820ddb3bded3b3b990ff1238/8ce52/button-mobile-truncation-dont.png",
                    "srcSet": ["/static/acd45935820ddb3bded3b3b990ff1238/1efb2/button-mobile-truncation-dont.png 370w", "/static/acd45935820ddb3bded3b3b990ff1238/8ce52/button-mobile-truncation-dont.png 681w"],
                    "sizes": "(max-width: 681px) 100vw, 681px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
        </SideBySide>
        <h2 {...{
          "id": "variations-1"
        }}>{`Variations`}</h2>
        <h3 {...{
          "id": "primary-button-2"
        }}>{`Primary Button`}</h3>
        <p>{`Primary Buttons are high emphasis. Use minimally to draw attention to the highest priority action.
Too many primary buttons make it confusing for the user to understand what action they should take.
Not all screens require a Primary Button.`}</p>
        <p>{`Primary Buttons have three sizes: small, medium, and large. Icons are supported for every size and
can be positioned to the left or right.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/5f52223190310853311f832a659a84c9/536c7/button-mobile-primary-icons.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "18.91891891891892%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of Primary No Icon, Leading, Trailing, and Icon Only variants",
                "title": "Image of Primary No Icon, Leading, Trailing, and Icon Only variants",
                "src": "/static/5f52223190310853311f832a659a84c9/50383/button-mobile-primary-icons.png",
                "srcSet": ["/static/5f52223190310853311f832a659a84c9/1efb2/button-mobile-primary-icons.png 370w", "/static/5f52223190310853311f832a659a84c9/50383/button-mobile-primary-icons.png 740w", "/static/5f52223190310853311f832a659a84c9/536c7/button-mobile-primary-icons.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`Primary Buttons also have an inverse variant. While it looks similar to the default Secondary
Button, the default outline as well as the hover and focus states are different. Use this variant
when you need to place a Primary Button on a dark or colorful background such as blueberry400.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/cbd5b0d41b76ca80f7dbac8787a0e9c9/536c7/button-mobile-primary-inverse.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "18.91891891891892%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of Inverse Primary Buttons",
                "title": "Image of Inverse Primary Buttons",
                "src": "/static/cbd5b0d41b76ca80f7dbac8787a0e9c9/50383/button-mobile-primary-inverse.png",
                "srcSet": ["/static/cbd5b0d41b76ca80f7dbac8787a0e9c9/1efb2/button-mobile-primary-inverse.png 370w", "/static/cbd5b0d41b76ca80f7dbac8787a0e9c9/50383/button-mobile-primary-inverse.png 740w", "/static/cbd5b0d41b76ca80f7dbac8787a0e9c9/536c7/button-mobile-primary-inverse.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`Primary Buttons and their inverse variants support the Inactive, Pressed, and Disabled Interaction
States.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/ec20bcf1d6341e47e59e0c34e0097623/536c7/button-mobile-primary-interaction.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "18.91891891891892%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of Primary Buttons interaction states",
                "title": "Image of Primary Buttons interaction states",
                "src": "/static/ec20bcf1d6341e47e59e0c34e0097623/50383/button-mobile-primary-interaction.png",
                "srcSet": ["/static/ec20bcf1d6341e47e59e0c34e0097623/1efb2/button-mobile-primary-interaction.png 370w", "/static/ec20bcf1d6341e47e59e0c34e0097623/50383/button-mobile-primary-interaction.png 740w", "/static/ec20bcf1d6341e47e59e0c34e0097623/536c7/button-mobile-primary-interaction.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h3 {...{
          "id": "secondary-button-2"
        }}>{`Secondary Button`}</h3>
        <p>{`Secondary Buttons have a medium level of emphasis. Use them for non-critical actions. Secondary
Buttons can be used on most pages without restrictions and work well for multiple actions of equal
weight. They can be used in conjunction with a Primary Button or independently.`}</p>
        <p>{`Secondary Buttons have three sizes: small, medium, and large. Icons are supported for every size and
can be positioned to the left or right.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/b94b9d3a8e991f76d4bead2c8d3bad94/536c7/button-mobile-secondary-icons.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "18.91891891891892%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of Secondary No Icon, Leading, Trailing, and Icon Only variants",
                "title": "Image of Secondary No Icon, Leading, Trailing, and Icon Only variants",
                "src": "/static/b94b9d3a8e991f76d4bead2c8d3bad94/50383/button-mobile-secondary-icons.png",
                "srcSet": ["/static/b94b9d3a8e991f76d4bead2c8d3bad94/1efb2/button-mobile-secondary-icons.png 370w", "/static/b94b9d3a8e991f76d4bead2c8d3bad94/50383/button-mobile-secondary-icons.png 740w", "/static/b94b9d3a8e991f76d4bead2c8d3bad94/536c7/button-mobile-secondary-icons.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`Secondary Buttons also have an inverse variant. Use this when you need to place a Secondary Button
on a dark or colorful background such as blueberry400.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/843d6b75fb6c02dac8eb6a8cf14fd01d/536c7/button-mobile-secondary-inverse.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "18.91891891891892%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of Inverse Secondary Buttons",
                "title": "Image of Inverse Secondary Buttons",
                "src": "/static/843d6b75fb6c02dac8eb6a8cf14fd01d/50383/button-mobile-secondary-inverse.png",
                "srcSet": ["/static/843d6b75fb6c02dac8eb6a8cf14fd01d/1efb2/button-mobile-secondary-inverse.png 370w", "/static/843d6b75fb6c02dac8eb6a8cf14fd01d/50383/button-mobile-secondary-inverse.png 740w", "/static/843d6b75fb6c02dac8eb6a8cf14fd01d/536c7/button-mobile-secondary-inverse.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`Secondary Buttons and their inverse variants support the Inactive, Pressed, and Disabled Interaction
States.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/b484f850b1d0d821876c287fa299bd26/536c7/button-mobile-secondary-interaction.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "18.91891891891892%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of Secondary Buttons interaction states",
                "title": "Image of Secondary Buttons interaction states",
                "src": "/static/b484f850b1d0d821876c287fa299bd26/50383/button-mobile-secondary-interaction.png",
                "srcSet": ["/static/b484f850b1d0d821876c287fa299bd26/1efb2/button-mobile-secondary-interaction.png 370w", "/static/b484f850b1d0d821876c287fa299bd26/50383/button-mobile-secondary-interaction.png 740w", "/static/b484f850b1d0d821876c287fa299bd26/536c7/button-mobile-secondary-interaction.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h3 {...{
          "id": "tertiary-button-2"
        }}>{`Tertiary Button`}</h3>
        <p>{`Tertiary Buttons have the lowest emphasis. Use for less important actions that the user may not
often be looking to do. Tertiary Buttons have lower prominence as its container is not visible until
it is interacted with. Use Tertiary Buttons for supplemental actions such as “View More”, “Read
More” or “Select a File”. Tertiary Buttons are frequently used on List Items.`}</p>
        <p>{`Tertiary Buttons have two sizes on mobile: small, and medium. Icons are supported for every size and
can be positioned to the left or right with the iconPosition prop.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/cbb9de5ebbaeb5634e2c390ac152c087/536c7/button-mobile-tertiary-icons.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "18.91891891891892%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of Tertiary No Icon, Leading, Trailing, and Icon Only variants",
                "title": "Image of Tertiary No Icon, Leading, Trailing, and Icon Only variants",
                "src": "/static/cbb9de5ebbaeb5634e2c390ac152c087/50383/button-mobile-tertiary-icons.png",
                "srcSet": ["/static/cbb9de5ebbaeb5634e2c390ac152c087/1efb2/button-mobile-tertiary-icons.png 370w", "/static/cbb9de5ebbaeb5634e2c390ac152c087/50383/button-mobile-tertiary-icons.png 740w", "/static/cbb9de5ebbaeb5634e2c390ac152c087/536c7/button-mobile-tertiary-icons.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`Tertiary Buttons also have an inverse variant. Use this when you need to place a Tertiary Button on
a dark or colorful background such as blueberry400.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/81f110ac2ec4b2932a2c8d04cd18158d/536c7/button-mobile-tertiary-inverse.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "18.91891891891892%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of Inverse Tertiary Buttons",
                "title": "Image of Inverse Tertiary Buttons",
                "src": "/static/81f110ac2ec4b2932a2c8d04cd18158d/50383/button-mobile-tertiary-inverse.png",
                "srcSet": ["/static/81f110ac2ec4b2932a2c8d04cd18158d/1efb2/button-mobile-tertiary-inverse.png 370w", "/static/81f110ac2ec4b2932a2c8d04cd18158d/50383/button-mobile-tertiary-inverse.png 740w", "/static/81f110ac2ec4b2932a2c8d04cd18158d/536c7/button-mobile-tertiary-inverse.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`Tertiary Buttons and their inverse variants support the Inactive, Pressed, and Disabled Interaction
States.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/a9308386279e69858480c5f88981e7df/536c7/button-mobile-tertiary-interaction.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "18.91891891891892%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of Tertiary Buttons interaction states",
                "title": "Image of Tertiary Buttons interaction states",
                "src": "/static/a9308386279e69858480c5f88981e7df/50383/button-mobile-tertiary-interaction.png",
                "srcSet": ["/static/a9308386279e69858480c5f88981e7df/1efb2/button-mobile-tertiary-interaction.png 370w", "/static/a9308386279e69858480c5f88981e7df/50383/button-mobile-tertiary-interaction.png 740w", "/static/a9308386279e69858480c5f88981e7df/536c7/button-mobile-tertiary-interaction.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h2 {...{
          "id": "api-guidelines-1"
        }}>{`API Guidelines`}</h2>
        <h3 {...{
          "id": "component-definition"
        }}>{`Component Definition`}</h3>
        <pre><code parentName="pre" {...{
            "className": "language-kotlin"
          }}>{`@Composable
fun ButtonUiComponent(
    modifier: Modifier = Modifier,
    enabled: Boolean = true,
    size: ButtonSizeConfig = ButtonSizeConfig.Large,
    iconConfig: ButtonIconConfig = ButtonIconConfig.NoIcon,
    buttonStyle: ButtonStyles = primaryButtonStyle(),
    iconPainter: Painter? = null,
    contentPadding: PaddingValues = buttonPaddingValues(size, iconConfig),
    contentDescription: String = "",
    onClick: () -> Unit = {},
    content: @Composable RowScope.() -> Unit,
) {
`}</code></pre>
        <h3 {...{
          "id": "parameters-6"
        }}>{`Parameters`}</h3>
        <p>{`All undocumented parameters are native Android Compose types that can be found in the developer
documentation.`}</p>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Name`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Default`}</th>
              <th parentName="tr" {...{
                "align": "center"
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`enabled`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`Boolean`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`true`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Controls the enabled state of the button. When false, this button will not be clickable.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`size`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`ButtonSizeConfig`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`ButtonSizeConfig.Large`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Determines the size of the button using a ButtonSizeConfig enum.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`iconConfig`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`ButtonIconConfig`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`ButtonIconConfig.NoIcon`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Determines the position of a provided icon using a ButtonIconConfig.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`buttonStyle`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`ButtonStyles`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`primaryButtonStyle()`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Sets colors, border, and content padding based on defined primary, secondary, and tertiary button styles. Custom buttons can be made by using one of the default button styles with the .copy() function.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`iconPainter`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`Painter?`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`null`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`The icon painter to be used in the button.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`contentPadding`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`PaddingValues`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`largeNoIconPadding`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`The spacing values to apply internally between the container and the content.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`contentDescription`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`String`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`""`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Content Description for the icon.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`onClick`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`() -> Unit`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`{}`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Callback lambda that is executed when the user clicks on the button.`}</td>
            </tr>
          </tbody>
        </table>
        <h2 {...{
          "id": "accessibility-guidelines-2"
        }}>{`Accessibility Guidelines`}</h2>
        <p>{`Refreshed mobile guidelines will be coming soon!`}</p>
        <h2 {...{
          "id": "content-guidelines-2"
        }}>{`Content Guidelines`}</h2>
        <ul>
          <li parentName="ul">{`See the `}<a parentName="li" {...{
              "href": "/guidelines/content/ui-text/buttons-ctas"
            }}>{`Buttons and Calls to Action`}</a>{` page in the UI Text section
of the Content Style Guide for button language guidelines.`}</li>
          <li parentName="ul">{`When writing Tooltips to pair with Icon Buttons, refer to the
`}<a parentName="li" {...{
              "href": "/guidelines/content/ui-text/tooltips"
            }}>{`Tooltips`}</a>{` section of the Content Style Guide.`}</li>
        </ul>
      </TabPanel>
    </InternalContent>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      